html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#FFFFFF";
  height: 100%;
  /* overflow: hidden; */
}

code {
  font-family: "Cairo", sans-serif;
}

p {
  font-family: "Cairo", sans-serif;
}

#root {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

/* date Range Picker */
/* .ant-picker {
  padding: 0px !important;
}

.ant-picker-range {
  border: none;
  background: #fff !important;
}

.ant-picker-range-separator {
  display: none;
}

.ant-picker-input {
  display: none !important;
}

.anticon svg {
  width: 20px !important;
  height: 20px !important;
  color: #F28705 !important
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #F28705 !important;
  border-radius: 50% !important;
  color: #fff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #F28705 !important;
  border-radius: 50% !important;
  color: #fff !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #F28705 !important;
    border-radius: 50% !important;
}

.ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-in-range::before {
    background-color: rgb(242 135 5 / 0.5) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: rgb(242 135 5 / 0.5) !important;
}

@media (max-width: 768px) {
  .ant-picker-panels {
    flex-direction: column;
  }

  .ant-picker-dropdown {
    position: relative !important;  
  }
} */